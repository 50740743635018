<template>
  <tr>
    <td>{{ item.id }}</td>
    <td>
      <b-form-select
        v-model="item.province_id"
        :options="provinces"
        @change="changeProvince"
      ></b-form-select>
    </td>
    <td>
      <b-form-select
        v-model="item.location_id"
        :options="locationsHelp"
      ></b-form-select>
    </td>
    <td>
      <b-form-input :max="15" v-model="item.altura"></b-form-input>
    </td>
    <td>
      <b-form-input :max="250" v-model="item.calle"></b-form-input>
    </td>
    <td>
      <b-form-input :max="30" v-model="item.codigopostal"></b-form-input>
    </td>
    <td>
      <b-form-input :max="25" v-model="item.latitud"></b-form-input>
    </td>
    <td>
      <b-form-input :max="25" v-model="item.longitud"></b-form-input>
    </td>
    <td>
      <b-form-input :max="400" v-model="item.nota"></b-form-input>
    </td>
    <td>
      <b-form-input :max="120" v-model="item.refagrupador"></b-form-input>
    </td>
    <td>
      <b-form-input :max="120" v-model="item.idci"></b-form-input>
    </td>
    <td>
      <b-form-input
        :max="120"
        v-model="item.razonsocialagrupador"
      ></b-form-input>
    </td>
    <td>
      <b-form-input :max="120" v-model="item.direccionagrupador"></b-form-input>
    </td>
    <td>
      <b-form-input :max="120" v-model="item.calleagrupador"></b-form-input>
    </td>
    <td>
      <b-form-input :max="120" v-model="item.altagrupador"></b-form-input>
    </td>
    <td>
      <b-form-input :max="120" v-model="item.ecalleagrupador"></b-form-input>
    </td>
    <td>
      <b-form-input :max="120" v-model="item.barrioagrupador"></b-form-input>
    </td>
    <td>
      <b-form-input :max="120" v-model="item.localidadagrupador"></b-form-input>
    </td>
    <td>
      <b-form-input :max="120" v-model="item.partidoagrupador"></b-form-input>
    </td>
    <td>
      <b-form-input :max="120" v-model="item.provinciaagrupador"></b-form-input>
    </td>
    <td>
      <b-form-input :max="120" v-model="item.paisagrupador"></b-form-input>
    </td>
    <td>
      <b-form-input :max="120" v-model="item.refpunto"></b-form-input>
    </td>
    <td>
      <b-form-input :max="120" v-model="item.inihora1"></b-form-input>
    </td>
    <td>
      <b-form-input :max="120" v-model="item.inihora2"></b-form-input>
    </td>
    <td>
      <b-form-input :max="120" v-model="item.finhora1"></b-form-input>
    </td>
    <td>
      <b-form-input :max="120" v-model="item.finhora2"></b-form-input>
    </td>
    <td>
      <b-form-input :max="120" v-model="item.contacto"></b-form-input>
    </td>
    <td>
      <b-form-input :max="120" v-model="item.direccionpunto"></b-form-input>
    </td>
    <td>
      <b-form-input :max="120" v-model="item.callepunto"></b-form-input>
    </td>
    <td>
      <b-form-input :max="120" v-model="item.ecallepunto"></b-form-input>
    </td>
    <td>
      <b-form-input :max="120" v-model="item.barriopunto"></b-form-input>
    </td>
    <td>
      <b-form-input :max="120" v-model="item.localidadpunto"></b-form-input>
    </td>
    <td>
      <b-form-input :max="120" v-model="item.partidopunto"></b-form-input>
    </td>
    <td>
      <b-form-input :max="120" v-model="item.provinciapunto"></b-form-input>
    </td>
    <td>
      <b-form-input :max="120" v-model="item.paispunto"></b-form-input>
    </td>
    <td>
      <b-form-input :max="120" v-model="item.codpostpunto"></b-form-input>
    </td>
    <td>
      <b-form-input :max="120" v-model="item.telepunto"></b-form-input>
    </td>
    <td>
      <b-form-input :max="120" v-model="item.telepunto2"></b-form-input>
    </td>
    <td>
      <b-form-input :max="120" v-model="item.telepunto3"></b-form-input>
    </td>
    <td>
      <b-form-input :max="120" v-model="item.mailpunto"></b-form-input>
    </td>
    <td>
      <b-form-input :max="120" v-model="item.pickup"></b-form-input>
    </td>
    <td>
      <b-form-input :max="120" v-model="item.dropoff"></b-form-input>
    </td>
  </tr>
</template>

<script>
export default {
  name: "UploadTr",
  props: ["locations", "provinces", "item"],
  data() {
    return {
      locationsHelp: null,
    };
  },
  methods: {
    changeProvince() {
      this.locationsHelp = this.locations.filter(
        (d) => d.province.value === this.item.province_id
      );
    },
  },
  mounted() {
    if (this.item.province) {
      this.locationsHelp = this.item.province.locations;
    }
  },
};
</script>

<style lang="scss" scoped>
.table-points {
  select,
  input {
    width: 180px;
  }
 
}
</style>