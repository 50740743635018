import axios from 'axios';

export default class LocationService {

    /**
     * 
     * @param {*} params 
     */
    static provinces() {
        return axios.get(process.env.VUE_APP_API + '/provinces', {
            params: { all: true }
        });
    }

    /**
     * 
     * @param {*} params 
     */
    static locations(params) {
        params.all = true;
        return axios.get(process.env.VUE_APP_API + '/locations', { params });
    }



    /**
     * @description get xml
     */
    static export(params = {}) {
        return axios.get(process.env.VUE_APP_API + '/locations/export', {
            params: params,
            responseType: 'blob'
        });
    }


    /** */
    static save(data) {
        if (data.id) {
            return this.update(data, data.id)
        }
        return this.store(data);
    }


    /**
     * 
     * @param {*} params 
     */
    static locations_charge(params) {
        return axios.get(process.env.VUE_APP_API + '/locations_charge', { params });
    }

    /**
     * 
     * @param {*} params 
     */
    static terms(data) {
        return axios.post(process.env.VUE_APP_API + '/price', data);
    }

    /**
     * @description login 
     * @param { username | login, password } values 
     */
    static index() {
        return axios.post(process.env.VUE_APP_API + '/locations');
    }

    /**
     * @description store data  
     * @param data { location_id, street, height,  postal_code } values 
     */
    static store(data) {
        return axios.post(process.env.VUE_APP_API + '/locations', data);
    }

    /**
     * @description get a element by id  
     * @param { id } id point 
     */
    static update(data, id) {
        return axios.put(process.env.VUE_APP_API + '/locations/' + id, data);
    }

    /**
     * @description delete a element 
     * @param { id } id point 
     */
    static destroy({ id }) {
        return axios.delete(process.env.VUE_APP_API + '/locations/' + id);
    }

    /**
     * @description get a element by id  
     * @param { id } id point 
     */
    static show(id) {
        return axios.get(process.env.VUE_APP_API + '/locations/' + id);
    }

    /**
     * 
     */
    static upload(locations) {
        return axios.post(process.env.VUE_APP_API + '/locations/upload', locations);
    }
}