<template>
  <b-card class="px-3 py-2">
    <b-card-title>
      <h3>Actualizar Puntos</h3>
    </b-card-title>
    <b-card-body>
      <loading
        :active="isLoading"
        :can-cancel="false"
        :is-full-page="false"
      ></loading>
      <b-row class="pl-1">
        <b-form @submit="onSubmit" inline>
          <b-form-group>
            <b-form-file
              v-model="form.file"
              accept=".xls, .xlsx, .csv"
              ref="file"
              @change="onChangeFile"
            >
            </b-form-file>
          </b-form-group>
          <b-button
            ref="submitButton"
            class="ml-2"
            variant="primary"
            type="submit"
            :disabled="!form.file"
          >
            Procesar
          </b-button>
        </b-form>
      </b-row>
      <b-row class="pl-1">
        <div class="alert alert-primary mt-2" role="alert" ref="alert">
          <small>
            El archivo a subir debe tener el mismo formato con el cual se
            exportó.
          </small>
        </div>
      </b-row>

      <b-row v-if="points">
        <div class="table-responsive" style="height: 400px">
          <table class="table-points table table-striped">
            <thead>
              <tr>
                <th>ID</th>
                <th>PROVINCIA</th>
                <th>LOCALIDAD</th>
                <th>ALTURA</th>
                <th>CALLE</th>
                <th>CODIGO POSTAL</th>
                <th>LATITUD</th>
                <th>LONGITUD</th>
                <th>NOTA</th>
                <th>REF AGRUPADOR</th>
                <th>IDCI</th>
                <th>RAZON SOCIAL AGRUPADOR</th>
                <th>IDCI</th>
                <th>DIRECCION AGRUPADOR</th>
                <th>CALLE AGRUPADOR</th>
                <th>ALT AGRUPADOR</th>
                <th>E CALLE AGRUPADOR</th>
                <th>BARRIO AGRUPADOR</th>
                <th>LOCALID ADAGRUPADOR</th>
                <th>PARTIDO AGRUPADOR</th>
                <th>PROVINCIA AGRUPADOR</th>
                <th>PAIS AGRUPADOR</th>
                <th>REF PUNTO</th>
                <th>INI HORA1</th>
                <th>INI HORA2</th>
                <th>FIN HORA1</th>
                <th>FIN HORA2</th>
                <th>CONTACTO</th>
                <th>DIRECCION PUNTO</th>
                <th>CALLE PUNTO ALT PUNTO</th>
                <th>E CALLE PUNTO</th>
                <th>BARRIO PUNTO</th>
                <th>LOCALIDAD PUNTO</th>
                <th>PARTIDO PUNTO</th>
                <th>PROVINCIA PUNTO</th>
                <th>PAIS PUNTO</th>
                <th>COD POST PUNTO</th>
                <th>TELE PUNTO</th>
                <th>TELE PUNTO2</th>
                <th>TELE PUNTO3</th>
                <th>MAIL PUNTO</th>
                <th>PICKUP</th>
                <th>DROPOFF</th>
              </tr>
            </thead>
            <tbody>
              <upload-tr
                v-for="(item, index) in points"
                :provinces="provinces"
                :locations="locations"
                :key="index"
                :item="item"
              />
            </tbody>
          </table>
        </div>
        <b-col>
          <b-button variant="primary" class="float-right" @click="onSave"
            >Guardar</b-button
          >
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import * as _ from "lodash";
import * as XLSX from "xlsx";
import AlertService from "@/services/AlertService";
import LocationService from "@/services/LocationService";
import PointService from "@/services/PointService";

import UploadTr from "./components/UploadTr";

export default {
  name: "PointUpload",
  components: { UploadTr },
  data() {
    return {
      isLoading: null,
      form: {
        file: null,
      },
      points: null,
      locations: null,
      provinces: null,
    };
  },
  methods: {
    /**
     *
     */
    onSubmit(event) {
      event.preventDefault();
      this.isLoading = true;
      const readtWithString = (wb, name) => {
        const elements = XLSX.utils
          .sheet_to_json(wb.Sheets[name])
          .map((item) => {
            const val = {};
            Object.keys(item).forEach((key) => {
              const key2 = key.toLowerCase().replace(/\s/g, "");
              val[key2] = String(item[key]).trim();
            });
            return val;
          });
        return elements;
      };
      const file = this.form.file;
      const reader = new FileReader();
      reader.onload = (e) => {
        // validate
        const provinces = {};
        _.uniqBy(
          this.locations.map((d) => d.province).flat(1),
          "value"
        ).forEach((d) => {
          const locations = this.locations.filter(
            (item) => item.province.value == d.value
          );
          provinces[d.text] = Object.assign({ locations }, d);
        });
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        this.points = readtWithString(wb, wb.SheetNames[0]).map((data) => {
          const province = provinces[data.provincia];
          let location = null;
          if (province) {
            location = province.locations.find((d) => {
              return d.text === data.localidad;
            });
          }
          return {
            pickup: data.pickup,
            dropoff: data.dropoff,
            location_id: location ? location.value : "",
            province_id: province ? province.value : "",
            province: province,
            altagrupador: data.altagrupador,
            altpunto: data.altpunto,
            altura: data.altura,
            barrioagrupador: data.barrioagrupador,
            barriopunto: data.barriopunto,
            calle: data.calle,
            calleagrupador: data.calleagrupador,
            callepunto: data.callepunto,
            codigopostal: data.codigopostal,
            codpostpunto: data.codpostpunto,
            contacto: data.contacto,
            direccionagrupador: data.direccionagrupador,
            direccionpunto: data.direccionpunto,
            ecalleagrupador: data.ecalleagrupador,
            ecallepunto: data.ecallepunto,
            finhora1: data.finhora1,
            finhora2: data.finhora2,
            id: data.id,
            idci: data.idci,
            inihora1: data.inihora1,
            inihora2: data.inihora2,
            latitud: data.latitud,
            localidad: data.localidad,
            localidadagrupador: data.localidadagrupador,
            localidadpunto: data.localidadpunto,
            longitud: data.longitud,
            mailpunto: data.mailpunto,
            nota: data.nota,
            paisagrupador: data.paisagrupador,
            paispunto: data.paispunto,
            partidoagrupador: data.partidoagrupador,
            partidopunto: data.partidopunto,
            provincia: data.provincia,
            provinciaagrupador: data.provinciaagrupador,
            provinciapunto: data.provinciapunto,
            razonsocialagrupador: data.razonsocialagrupador,
            refagrupador: data.refagrupador,
            refpunto: data.refpunto,
            telepunto: data.telepunto,
            telepunto2: data.telepunto2,
            telepunto3: data.telepunto3,
            codpostagrupador: data.codpostagrupador,
            mailagrupador: data.mailagrupador,
            contactoagrupador: data.contactoagrupador,
            nombrepunto: data.nombrepunto,
          };
        });
        this.isLoading = false;
      };
      reader.readAsBinaryString(file);
    },

    /**
     *
     */
    onChangeFile() {
      this.points = null;
    },

    /**
     *
     */
    onSave() {
      this.isLoading = true;
      PointService.upload(
        this.points.map((d) => {
          return {
            altagrupador: d.altagrupador,
            altpunto: d.altpunto,
            altura: d.altura,
            barrioagrupador: d.barrioagrupador,
            barriopunto: d.barriopunto,
            calle: d.calle,
            calleagrupador: d.calleagrupador,
            callepunto: d.callepunto,
            codigopostal: d.codigopostal,
            codpostpunto: d.codpostpunto,
            contacto: d.contacto,
            direccionagrupador: d.direccionagrupador,
            direccionpunto: d.direccionpunto,
            ecalleagrupador: d.ecalleagrupador,
            ecallepunto: d.ecallepunto,
            finhora1: d.finhora1,
            finhora2: d.finhora2,
            id: d.id,
            idci: d.idci,
            inihora1: d.inihora1,
            inihora2: d.inihora2,            
            latitud: d.latitud,
            localidad: d.localidad,
            localidadagrupador: d.localidadagrupador,
            localidadpunto: d.localidadpunto,
            location_id: d.location_id,
            longitud: d.longitud,
            mailpunto: d.mailpunto,
            nota: d.nota,
            paisagrupador: d.paisagrupador,
            paispunto: d.paispunto,
            partidoagrupador: d.partidoagrupador,
            partidopunto: d.partidopunto,
            province_id: d.province_id,
            provincia: d.provincia,
            provinciaagrupador: d.provinciaagrupador,
            provinciapunto: d.provinciapunto,
            razonsocialagrupador: d.razonsocialagrupador,
            refagrupador: d.refagrupador,
            refpunto: d.refpunto,
            telepunto: d.telepunto,
            telepunto2: d.telepunto2,
            telepunto3: d.telepunto3,
            codpostagrupador: d.codpostagrupador,
            mailagrupador: d.mailagrupador,
            contactoagrupador: d.contactoagrupador,
            nombrepunto: d.nombrepunto,
            pickup: (d.pickup || "").toUpperCase() == "SI" ? true : false,
            dropoff: (d.dropoff || "").toUpperCase() == "SI" ? true : false,
          };
        })
      )
        .then(() => {
          this.isLoading = false;
          AlertService.info();
          this.$router.push({ name: "Puntos" });
        })
        .catch((error) => {
          this.isLoading = false;
          console.error(error);
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Actualizar`,
              text: `Error al tratar de almacenar este elemento`,
              icon: "AlertOctagonIcon",
              variant: "danger",
            },
          });
        });
    },
  },
  mounted() {
    this.isLoading = true;
    LocationService.locations({})
      .then((response) => {
        this.isLoading = false;
        const { data } = response;
        this.locations = data.data;
        this.provinces = _.uniqBy(
          this.locations.map((d) => d.province).flat(1),
          "value"
        );
      })
      .catch((error) => {
        this.isLoading = false;
        console.error(error);
      });
  },
};
</script>

<style lang="scss" scoped>
.table-points {
  th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
    background: white;
  }
}
</style>
