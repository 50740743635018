import axios from 'axios';

export default class PointService {

    /**
     * @description login 
     * @param { username | login, password } values 
     */
    static index() {
        return axios.post(process.env.VUE_APP_API + '/points');
    }

    /**
     * @description store data  
     * @param data { location_id, street, height,  postal_code } values 
     */
    static store(data) {
        return axios.post(process.env.VUE_APP_API + '/points', data);
    }

    /**
     * 
     * @param {*} data 
     * @param {*} id 
     */
    static upload(data) {
        return axios.post(process.env.VUE_APP_API + '/points/upload', data);
    }

    /**
     * 
     * @param {*} data 
     * @param {*} id 
     */
    static save(data, id) {
        return id ? this.updated(id, data) : this.store(data, id);
    }

    /**
     * @description delete a element 
     * @param { id } id point 
     */
    static destroy({ id }) {
        return axios.delete(process.env.VUE_APP_API + '/points/' + id);
    }

    /**
     * @description update a element 
     * @param { id } id point 
     * @param values { location_id, street, height,  postal_code } update values
     */
    static updated(id, values) {
        return axios.put(process.env.VUE_APP_API + '/points/' + id, values);
    }

    /**
     * @description get a element by id  
     * @param { id } id point 
     */
    static show(id) {
        return axios.get(process.env.VUE_APP_API + '/points/' + id);
    }

    /**
     * @description get xml
     */
    static export(params = {}) {
        return axios.get(process.env.VUE_APP_API + '/points/export', {
            params: params,
            responseType: 'blob'
        });
    }

}